import React from "react";
import "./PrintSettingsComponent.scss";
import Box from "@material-ui/core/Box";
import {SymbolLayoutComponent} from "./SymbolLayoutComponent";
import {CardLayoutComponent} from "./CardLayoutComponent";
import Divider from "@material-ui/core/Divider";
import {PageLayoutComponent} from "./PageLayoutComponent";

/**
 * @typedef PrintSettings
 * @type {object}
 * @property {number} pageWidth
 * @property {number} pageHeight
 * @property {number} pageBorder
 * @property {number} cardLayout
 * @property {number} cardWidth
 * @property {number} cardHeight
 * @property {number} cardSpacing
 * @property {number} symbolWidth
 * @property {number} symbolHeight
 */

/**
 * @typedef TacticalSymbol
 * @type {object}
 * @property {string} path
 * @property {string} name
 * @property {?number} amount
 */

/**
 * @typedef {Array<TacticalSymbol>} Group
 */

/**
 * @typedef {Object<string, Group>} Groups
 */

/**
 *
 * @param {Object} props
 * @param {PrintSettings} props.printSettings
 * @return {*}
 * @constructor
 */
export function PrintSettingsComponent(props) {
    const {
        printSettings,
        updatePrintSettings
    } = props;

    return (
        <Box pt={2} className="print-settings">
            <PageLayoutComponent printSettings={printSettings}
                                 updatePrintSettings={updatePrintSettings}/>

            <Divider/>

            <CardLayoutComponent printSettings={printSettings}
                                 updatePrintSettings={updatePrintSettings}/>

            <Divider/>

            <SymbolLayoutComponent printSettings={printSettings}
                                   updatePrintSettings={updatePrintSettings}/>
        </Box>
    );
}