import {useEffect, useState} from "react";

/**
 * Debounce a value.
 *
 * @param {*} value
 * @param {number} delay
 * @return {*}
 */
export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
}

/**
 * Create a fuzzy search regexp from value.
 *
 * @param {string} value
 * @return {?RegExp}
 */
export function fuzzyRegex(value) {
    const pattern = value.split("")
        .filter(s => /\w/.exec(s))
        .join("[\\s._-]*");

    return pattern ? new RegExp(pattern, "i") : null;
}
