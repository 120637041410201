import React from "react";
import "./ViewSettingsComponent.scss";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiDialogContent from '@material-ui/core/DialogContent';
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import PaletteIcon from '@material-ui/icons/Palette';
import IconButton from "@material-ui/core/IconButton";
import {Tooltip} from "@material-ui/core";

const DialogContent = withStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        padding: theme.spacing(2),
        width: "240px",
    },
}))(MuiDialogContent);

const ColorFab = withStyles(theme => ({
    root: {
        margin: "10px",
    }
}))(Fab);

export function SimpleColorPickerComponent(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    let {
        colors,
        color,
        onChange,
        ...others
    } = props;

    if (others.style) {
        others.style = Object.assign({}, others.style, {color: color || "#add8e6"});
    } else {
        others.style = {color: color || "#add8e6"};
    }

    if (!colors) {
        colors = [
            "#add8e6", "#83c0e6", "#5596e6", "#275de6",
            "#bde6ae", "#8ee683", "#55e65c", "#27e670",
            "#e6b1ae", "#e69083", "#e67455", "#e66027",
        ];
    }

    const handleChange = (color) => {
        setOpen(false);
        onChange(color);
    };

    return (
        <Box component="span">
            <IconButton size={"small"} onClick={handleClickOpen} {...others}>
                <PaletteIcon/>
            </IconButton>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogContent>
                    {colors.map(color => (
                        <Tooltip key={color} title={color}>
                            <ColorFab
                                style={({backgroundColor: color})}
                                size={"small"}
                                onClick={() => handleChange(color)}
                            >&nbsp;</ColorFab>
                        </Tooltip>
                    ))}
                </DialogContent>
            </Dialog>
        </Box>
    );
}
