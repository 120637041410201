import React, {useEffect, useState} from "react";
import "./SymbolSelectorComponent.scss";
import {fuzzyRegex, useDebounce} from "./functions";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {ListItemAvatar} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Alert from "@material-ui/lab/Alert";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Divider from "@material-ui/core/Divider";

/**
 * @typedef PrintSettings
 * @type {object}
 * @property {number} pageWidth
 * @property {number} pageHeight
 * @property {number} pageBorder
 * @property {number} cardLayout
 * @property {number} cardWidth
 * @property {number} cardHeight
 * @property {number} cardSpacing
 * @property {number} symbolWidth
 * @property {number} symbolHeight
 */

/**
 * @typedef TacticalSymbol
 * @type {object}
 * @property {string} path
 * @property {string} name
 * @property {?number} amount
 */

/**
 * @typedef {Array<TacticalSymbol>} Group
 */

/**
 * @typedef {Object<string, Group>} Groups
 */

/**
 *
 * @param {Object} props
 * @param {PrintSettings} props.printSettings
 * @param {Groups} props.groups
 * @return {*}
 * @constructor
 */
export function SymbolSelectorComponent(props) {
    const {
        groups,
        selectedSymbols,
        setSelectedSymbols
    } = props;
    const [selectedGroup, setSelectedGroup] = useState(localStorage.getItem("selectedGroup") || "");
    const [search, setSearch] = useState(localStorage.getItem("search") || "");
    const debouncedSearch = useDebounce(search, 500);
    const [visibleSymbols, setVisibleSymbols] = useState([]);

    function addSymbol(symbol, amount) {
        setSelectedSymbols(selectedSymbols => {
            const index = selectedSymbols.findIndex(item => {
                return item.path === symbol.path;
            });

            const result = selectedSymbols.slice(0);
            if (-1 === index) {
                symbol.amount = amount;
                result.push(symbol);
                result.sort(
                    /**
                     *
                     * @param {TacticalSymbol} a
                     * @param {TacticalSymbol} b
                     * @return {*}
                     */
                    (a, b) => a.path.localeCompare(b.path)
                );
            } else {
                result[index].amount = (result[index].amount || 1) + amount;
            }

            return result;
        });
    }

    function removeSymbol(symbol, amount) {
        setSelectedSymbols(selectedSymbols => {
            const index = selectedSymbols.findIndex(item => {
                return item.path === symbol.path;
            });

            if (-1 === index) {
                return selectedSymbols;
            }

            const result = selectedSymbols.slice(0);
            if (result[index].amount <= amount) {
                if (result[index].amount > 1) {
                    result[index].amount = 1;
                } else {
                    result.splice(index, 1);
                }
            } else {
                result[index].amount -= amount;
            }
            return result;
        });
    }

    function addAllSymbols(symbols, amount) {
        symbols.forEach(symbol => addSymbol(symbol, amount));
    }

    /**
     * Remove the amount of all symbols or clear the selected symbols list.
     * @param {?TacticalSymbol[]} symbols
     * @param {?number} amount
     */
    function removeAllSymbols(symbols, amount) {
        if (undefined === symbols) {
            setSelectedSymbols([]);
        } else {
            symbols.forEach(symbol => removeSymbol(symbol, amount));
        }
    }

    useEffect(() => {
        localStorage.setItem("selectedGroup", selectedGroup);
    }, [selectedGroup]);

    useEffect(() => {
        localStorage.setItem("search", search);
    }, [search]);

    useEffect(() => {
        const searchRegex = debouncedSearch ? fuzzyRegex(debouncedSearch) : null;
        if (selectedGroup || debouncedSearch) {
            const result = Object.entries(groups).flatMap(([title, symbols]) => {
                if (!selectedGroup || selectedGroup === title) {
                    if (searchRegex) {
                        return symbols.filter(
                            /** @param {TacticalSymbol} symbol */
                            symbol => searchRegex.exec(symbol.name)
                        );
                    } else {
                        return symbols;
                    }
                } else {
                    return [];
                }
            }).filter(symbol => {
                return !selectedSymbols.find(item => item.path === symbol.path);
            });
            setVisibleSymbols(result);
        } else {
            setVisibleSymbols([]);
        }
    }, [groups, selectedGroup, debouncedSearch, selectedSymbols]);

    return (
        <Grid container className="selection-area">
            <Grid item xs={7}>
                <Box pt={2}>
                    <Box p={1}>
                        <Button variant="contained" color="secondary"
                                onClick={(event) => removeAllSymbols(selectedSymbols, event.ctrlKey ? 10 : event.shiftKey ? 5 : 1)}
                                disabled={!selectedSymbols.length}>
                            <RemoveIcon/>
                            Alle verringern
                        </Button>
                        &nbsp;
                        <Button variant="contained" color="primary"
                                onClick={(event) => addAllSymbols(selectedSymbols, event.ctrlKey ? 10 : event.shiftKey ? 5 : 1)}
                                disabled={!selectedSymbols.length}>
                            <AddIcon/>
                            Alle erhöhen
                        </Button>
                    </Box>
                    <Divider/>
                    {selectedSymbols.length ? (
                        <List>
                            {selectedSymbols.map(symbol => (
                                <ListItem key={symbol.path} alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar src={symbol.path} alt={symbol.name}/>
                                    </ListItemAvatar>
                                    <ListItemText primary={symbol.name}
                                                  secondary={<Box component="span">{symbol.amount || 1} &times;</Box>}/>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" size="small"
                                                    onClick={(event) => removeSymbol(symbol, event.ctrlKey ? 10 : event.shiftKey ? 5 : 1)}>
                                            <RemoveIcon/>
                                        </IconButton>
                                        <IconButton edge="end" size="small"
                                                    onClick={(event) => addSymbol(symbol, event.ctrlKey ? 10 : event.shiftKey ? 5 : 1)}>
                                            <AddIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Box p={1}>
                            <Alert severity="warning">
                                Keine Zeichen ausgewählt!
                            </Alert>
                        </Box>
                    )}
                    <Divider/>
                    <Box p={1}>
                        <Button variant="contained" color="secondary" onClick={() => removeAllSymbols()}
                                disabled={!selectedSymbols.length}>
                            <DeleteSweepIcon/>
                            Alle entfernen
                        </Button>
                    </Box>
                    <Divider/>
                    <Box p={1}>
                        <Typography variant={"body2"}>
                            Tipp:<br/>
                            Umschalt + Minus/Plus verringert/erhöht in 5er Schritten.<br/>
                            Strg + Minus/Plus verringert/erhöht in 10er Schritten.
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={5}>
                <Box p={1} pt={3}>
                    <TextField fullWidth={true}
                               label="Organisation"
                               select
                               value={selectedGroup}
                               onChange={(event) => setSelectedGroup(event.target.value)}>
                        <MenuItem value={""}>Alle</MenuItem>
                        {Object.keys(groups).map((title) => (
                            <MenuItem key={title} value={title}>{title}</MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box p={1}>
                    <TextField fullWidth={true}
                               placeholder="Suche"
                               value={search}
                               onInput={(event) => setSearch(event.target.value)}/>
                </Box>

                {search !== debouncedSearch ? <Box p={1}><LinearProgress/></Box> : null}

                {visibleSymbols.length ? (
                    <Box>
                        <Box p={1}>
                            <Button variant="contained" color="primary"
                                    onClick={(event) => addAllSymbols(visibleSymbols, event.ctrlKey ? 10 : event.shiftKey ? 5 : 1)}>
                                <PlaylistAddIcon/>
                                Alle hinzufügen
                            </Button>
                        </Box>

                        <List>
                            {visibleSymbols.map(symbol =>
                                <ListItem key={symbol.path} alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar src={symbol.path} alt={symbol.name}/>
                                    </ListItemAvatar>
                                    <ListItemText primary={symbol.name}/>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" size="small"
                                                    onClick={(event) => addSymbol(symbol, event.ctrlKey ? 10 : event.shiftKey ? 5 : 1)}>
                                            <AddIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </List>
                    </Box>
                ) : (
                    <Alert severity="warning" style={({flexGrow: 1})}>
                        Keine Zeichen zur Auswahl, wähle eine Organisation oder gib einen (anderen) Suchbegriff ein.
                    </Alert>
                )}
            </Grid>
        </Grid>
    );
}