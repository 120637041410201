import Paper from "@material-ui/core/Paper";
import "./PrintAreaComponent.scss";
import Box from "@material-ui/core/Box";
import React, {useEffect, useState} from "react";
import Alert from "@material-ui/lab/Alert";
import {CardLayout} from "./App";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DetailsIcon from '@material-ui/icons/Details';
import Fab from "@material-ui/core/Fab";
import {Tooltip} from "@material-ui/core";

/**
 * @typedef PrintSettings
 * @type {object}
 * @property {number} pageWidth
 * @property {number} pageHeight
 * @property {number} pageBorder
 * @property {number} cardLayout
 * @property {number} cardWidth
 * @property {number} cardHeight
 * @property {number} cardSpacing
 * @property {number} symbolWidth
 * @property {number} symbolHeight
 */

/**
 * @typedef PageMeta
 * @type {object}
 * @property {number} printWidth
 * @property {number} printHeight
 * @property {number} cardWidth
 * @property {number} cardHeight
 * @property {number} columns
 * @property {number} rows
 * @property {number} perPage
 * @property {number} total
 */

/**
 * @typedef TacticalSymbol
 * @type {object}
 * @property {string} path
 * @property {string} name
 * @property {?number} amount
 */

const useStyles = makeStyles(theme => ({
    fab: {
        position: "absolute",
        top: ".5cm",
        right: ".5cm",
    },
    showPageMetaButton: {
        position: "absolute",
        top: ".5cm",
        right: ".5cm",
    },
}));

/**
 * Group selected symbols by pages, depend on the print settings.
 *
 * @param {PageMeta} pageMeta
 * @param {TacticalSymbol[]} symbols
 * @return {Array<TacticalSymbol[]>}
 */
function groupIntoPages(pageMeta, symbols) {
    const {perPage} = pageMeta;

    const pages = [];
    for (let index = 0; index < symbols.length; index += perPage) {
        pages.push(symbols.slice(index, index + perPage));
    }
    return pages;
}

function SymbolComponent(props) {
    const {viewSettings, printSettings, pageMeta, symbolIndex, symbol} = props;

    const {
        showCardOutline,
        showSymbolOutline,
    } = viewSettings;

    let {
        cardSpacing,
        cardLayout,
        cardWidth,
        cardHeight,
        cardOutline,
        cardOutlineColor,
        symbolWidth,
        symbolHeight,
        textLineColor,
    } = printSettings;

    let textLineWidth = cardWidth - 10;
    let textLines = 1;

    if (symbolWidth > cardWidth) {
        symbolWidth = cardWidth;
    }

    if (symbolHeight > cardHeight) {
        symbolHeight = cardHeight;
    }

    if (CardLayout.SYMBOL_WITH_SUBLINE === cardLayout) {
        symbolHeight -= 15;
    } else if (CardLayout.SYMBOL_WITH_DETAILS === cardLayout) {
        textLineWidth = cardWidth - symbolWidth - 10;
        textLines = Math.floor(cardHeight / 10)
    }

    const {
        columns,
        rows
    } = pageMeta;

    const column = 1 + (symbolIndex % columns);
    const row = 1 + Math.floor(symbolIndex / columns);

    const className = "tactical-symbol-wrapper-inner" +
        (CardLayout.SYMBOL_WITH_SUBLINE === cardLayout ? " with-subline" : "") +
        (CardLayout.SYMBOL_WITH_DETAILS === cardLayout ? " width-details" : "") +
        ((showCardOutline || cardOutline) ? " outlined" : "");

    return (
        <Box component="div"
             style={({
                 width: (cardWidth + (column < columns ? cardSpacing : 0)) + "mm",
                 height: cardHeight + (row < rows ? cardSpacing : 0) + "mm",
             })}
             className={"tactical-symbol-wrapper"}>
            <div className={className}
                 style={({
                     width: cardWidth + "mm",
                     height: cardHeight + "mm",
                     borderColor: cardOutlineColor,
                 })}>
                <img src={symbol.path}
                     alt={symbol.name}
                     style={({
                         width: symbolWidth + "mm",
                         height: symbolHeight + "mm"
                     })}
                     className={"tactical-symbol-image" + (showSymbolOutline ? " outlined" : "")}/>

                {CardLayout.SYMBOL_WITH_SUBLINE === cardLayout ? (
                    <div className="text-line" style={({width: textLineWidth + "mm"})}/>
                ) : null}

                {CardLayout.SYMBOL_WITH_DETAILS === cardLayout ? (
                    <div className="text-lines">
                        {_.times(textLines, (index) => (
                            <div
                                key={index}
                                className="text-line"
                                style={({width: textLineWidth + "mm", borderColor: textLineColor})}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        </Box>
    );
}

/**
 * The "print paper" component.
 *
 * @param props
 * @return {*}
 * @constructor
 */
export function PrintAreaComponent(props) {
    const classes = useStyles();

    const {viewSettings, printSettings, selectedSymbols} = props;
    const [symbolsToPrint, setSymbolsToPrint] = useState([]);
    const [pages, setPages] = useState([]);
    const [showPageMeta, setShowPageMeta] = useState(false);
    const [pageMeta, setPageMeta] = useState({});

    const {
        showBorderArrows,
        showBorderOutline,
    } = viewSettings;
    const {
        cropMarks,
        cropMarksColor,
        pageWidth,
        pageHeight,
        pageBorder,
        cardWidth,
        cardHeight,
        cardSpacing,
    } = printSettings;

    useEffect(() => {
        const symbols = new Array(pageMeta.total || 0);
        let index = 0;
        selectedSymbols.forEach(symbol => {
            const amount = Math.max(symbol.amount || 1, 1);
            symbols.fill(symbol, index, index + amount);
            index += amount;
        });
        setSymbolsToPrint(symbols);
    }, [pageMeta, selectedSymbols]);

    useEffect(() => {
        setPages(groupIntoPages(pageMeta, symbolsToPrint));
    }, [pageMeta, symbolsToPrint]);

    useEffect(() => {
        const printWidth = pageWidth - (2 * pageBorder) + cardSpacing;
        const printHeight = pageHeight - (2 * pageBorder) + cardSpacing;
        const cardWidthAndSpacing = (cardWidth + cardSpacing);
        const cardHeightAndSpacing = (cardHeight + cardSpacing);

        const columns = Math.max(1, Math.floor(printWidth / cardWidthAndSpacing));
        const rows = Math.max(1, Math.floor(printHeight / cardHeightAndSpacing));
        const perPage = columns * rows;

        const total = selectedSymbols.map(symbol => Math.max(symbol.amount || 1, 1))
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

        setPageMeta({
            printWidth,
            printHeight,
            cardWidth: cardWidthAndSpacing,
            cardHeight: cardHeightAndSpacing,
            columns,
            rows,
            perPage,
            total,
        })
    }, [pageWidth, pageHeight, pageBorder, cardWidth, cardHeight, cardSpacing, selectedSymbols]);

    // const css = `@media print { .paper { padding: ${pageBorder - cardSpacing}mm !important; } }`;

    return (
        <main className="print-area">
            {/*<style>{css}</style>*/}

            {showPageMeta ? (
                <Paper className="page-meta" elevation={6}>
                    <IconButton size="small" className={classes.fab} onClick={() => setShowPageMeta(false)}>
                        <CloseIcon/>
                    </IconButton>

                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={2}>
                                    <strong>Druckbereich</strong> (abzüglich Druckrand, inklusive Kartenabstand)
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Breite</TableCell>
                                <TableCell>{pageMeta.printWidth}mm = {pageWidth}mm - (2 * {pageBorder}mm)
                                    + {cardSpacing}mm</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Höhe</TableCell>
                                <TableCell>{pageMeta.printHeight}mm = {pageHeight}mm - (2 * {pageBorder}mm)
                                    + {cardSpacing}mm</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={2}>
                                    <strong>Karten</strong> (inklusive Kartenabstand)
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Breite</TableCell>
                                <TableCell>{pageMeta.cardWidth}mm = {cardWidth}mm + {cardSpacing}mm</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Höhe</TableCell>
                                <TableCell>{pageMeta.cardHeight}mm = {cardHeight}mm + {cardSpacing}mm</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={2}>
                                    <strong>Symbole</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Spalten</TableCell>
                                <TableCell>{pageMeta.columns} = {pageMeta.printWidth}mm
                                    / {pageMeta.cardWidth}mm</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Zeilen</TableCell>
                                <TableCell>{pageMeta.rows} = {pageMeta.printHeight}mm
                                    / {pageMeta.cardHeight}mm</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">pro Seite</TableCell>
                                <TableCell>{pageMeta.perPage} = {pageMeta.columns} * {pageMeta.rows}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Gesamt</TableCell>
                                <TableCell>{pageMeta.total}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            ) : (
                <div className="page-meta-button">
                    <Tooltip
                        title="Detaillierte Meta Informationen zu Druckbereich, Kartengröße und Symbolanordnung anzeigen"
                        placement="left">
                        <Fab className={classes.showPageMetaButton} onClick={() => setShowPageMeta(true)} size="small">
                            <DetailsIcon/>
                        </Fab>
                    </Tooltip>
                </div>
            )}

            {pages.length ? pages.map((symbols, pageIndex) =>
                <Paper key={pageIndex} component="div" className="paper"
                       style={({
                           width: pageWidth + "mm",
                           height: (pageHeight - 1) + "mm",
                           padding: pageBorder + "mm",
                       })}
                       elevation={6}>

                    <div className={"paper-inner" + (showBorderOutline ? " outlined" : "")}
                         style={({
                             width: (pageWidth - (2 * pageBorder)) + "mm",
                             height: (pageHeight - (2 * pageBorder) - 1) + "mm",
                         })}
                         elevation={6}>

                        {showBorderArrows ? (
                            <code className="border-indicator border-top-left-indicator" style={({
                                width: (pageBorder - .5) + "mm",
                                top: -pageBorder + "mm",
                                left: (5 - pageBorder) + "mm",
                            })}>{pageBorder}mm</code>
                        ) : null}

                        {showBorderArrows ? (
                            <code className="border-indicator border-top-right-indicator" style={({
                                width: (pageBorder - .5) + "mm",
                                top: -pageBorder + "mm",
                                right: "5mm",
                            })}>{pageBorder}mm</code>
                        ) : null}

                        {showBorderArrows ? (
                            <code className="border-indicator border-left-top-indicator" style={({
                                width: (pageBorder - .5) + "mm",
                                top: "5mm",
                                left: -pageBorder + "mm",
                            })}>{pageBorder}mm</code>
                        ) : null}

                        {showBorderArrows ? (
                            <code className="border-indicator border-right-top-indicator" style={({
                                width: (pageBorder - .5) + "mm",
                                top: "5mm",
                                right: -pageBorder + "mm",
                            })}>{pageBorder}mm</code>
                        ) : null}

                        {cropMarks ? _.times(pageMeta.columns, (index) => [
                            <div key={index + "-top"}
                                 className="crop-mark top"
                                 style={({
                                     height: (pageBorder - 2) + "mm",
                                     top: -pageBorder + "mm",
                                     left: (index * (cardWidth + cardSpacing)) + "mm",
                                     width: cardWidth + "mm",
                                     borderColor: cropMarksColor,
                                 })}/>,
                            <div key={index + "-bottom"}
                                 className="crop-mark bottom"
                                 style={({
                                     height: (pageBorder - 2) + "mm",
                                     bottom: -pageBorder + "mm",
                                     left: (index * (cardWidth + cardSpacing)) + "mm",
                                     width: cardWidth + "mm",
                                     borderColor: cropMarksColor,
                                 })}/>,
                        ]) : null}

                        {cropMarks ? _.times(pageMeta.rows, (index) => [
                            <div key={index + "-left"}
                                 className="crop-mark left"
                                 style={({
                                     width: (pageBorder - 2) + "mm",
                                     left: -pageBorder + "mm",
                                     top: (index * (cardHeight + cardSpacing)) + "mm",
                                     height: cardHeight + "mm",
                                     borderColor: cropMarksColor,
                                 })}/>,
                            <div key={index + "-right"}
                                 className="crop-mark right"
                                 style={({
                                     width: (pageBorder - 2) + "mm",
                                     right: -pageBorder + "mm",
                                     top: (index * (cardHeight + cardSpacing)) + "mm",
                                     height: cardHeight + "mm",
                                     borderColor: cropMarksColor,
                                 })}/>,
                        ]) : null}

                        <div className="print-outer">
                            <div className="print-inner">
                                {symbols.map((symbol, symbolIndex) => (
                                    <SymbolComponent key={symbol.path + "@" + pageIndex + "-" + symbolIndex}
                                                     viewSettings={viewSettings}
                                                     printSettings={printSettings}
                                                     pageMeta={pageMeta}
                                                     symbolIndex={symbolIndex}
                                                     symbol={symbol}/>
                                ))}
                            </div>
                        </div>

                    </div>

                </Paper>
            ) : (
                <Paper component="div" className="paper" elevation={6}>
                    <Alert severity="warning" style={({flexGrow: 1})}>
                        Bitte rechts Zeichen auswählen!
                    </Alert>
                </Paper>
            )}
        </main>
    )
}
