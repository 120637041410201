import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import React from "react";

export function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return value === index && (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}
