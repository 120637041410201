import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export function SymbolLayoutComponent(props) {
    const {
        printSettings,
        updatePrintSettings
    } = props;

    let symbolWidth = printSettings.symbolWidth;
    let symbolHeight = printSettings.symbolHeight;

    const setSymbolWidth = (value) => updatePrintSettings({symbolWidth: value});

    const setSymbolHeight = (value) => updatePrintSettings({symbolHeight: value});

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const loadCProfilePreset = () => {
        setSymbolWidth(45);
        setSymbolHeight(48);
        setAnchorEl(null);
    };

    const handleSymbolWidthChange = (event) => {
        setSymbolWidth(parseInt(event.target.value));
    };

    const handleSymbolHeightChange = (event) => {
        setSymbolHeight(parseInt(event.target.value));
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box p={1}>
                    <Typography variant="subtitle2" component="h2">
                        Symbollayout
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={4}>
                <Box p={1}>
                    <TextField label="Maximale Breite (mm)"
                               inputProps={({type: "number", min: 20, step: 1})}
                               value={symbolWidth}
                               onChange={handleSymbolWidthChange}/>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box p={1}>
                    <TextField label="Maximale Höhe (mm)"
                               inputProps={({type: "number", min: 20, step: 1})}
                               value={symbolHeight}
                               onChange={handleSymbolHeightChange}/>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box pt={2}>
                    <Tooltip title="Voreinstellung laden" children={(
                        <IconButton variant="contained" onClick={handleClick}>
                            <RotateLeftIcon/>
                        </IconButton>
                    )}/>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={loadCProfilePreset}>C-Profil (45mm &times; 48mm)</MenuItem>
                    </Menu>
                </Box>
            </Grid>
        </Grid>
    )
}