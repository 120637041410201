import React from "react";
import "./ViewSettingsComponent.scss";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {SimpleColorPickerComponent} from "./SimpleColorPickerComponent";

/**
 * @typedef PrintSettings
 * @type {object}
 * @property {number} pageWidth
 * @property {number} pageHeight
 * @property {number} pageBorder
 * @property {number} cardLayout
 * @property {number} cardWidth
 * @property {number} cardHeight
 * @property {number} cardSpacing
 * @property {boolean} cardOutline
 * @property {string} cardOutlineColor
 * @property {boolean} cropMarks
 * @property {string} cropMarksColor
 * @property {number} symbolWidth
 * @property {number} symbolHeight
 * @property {string} textLineColor
 */

/**
 * @typedef ViewSettings
 * @type {object}
 * @property {boolean} showBorderArrows
 * @property {boolean} showBorderOutline
 * @property {boolean} showSymbolOutline
 */

export function ViewSettingsComponent(props) {
    const {
        viewSettings,
        updateViewSettings,
        printSettings,
        updatePrintSettings
    } = props;

    const showBorderArrows = viewSettings.showBorderArrows;
    const showBorderOutline = viewSettings.showBorderOutline;
    const showCardOutline = viewSettings.showCardOutline;
    const showSymbolOutline = viewSettings.showSymbolOutline;
    const cardOutline = printSettings.cardOutline;
    const cardOutlineColor = printSettings.cardOutlineColor;

    const setShowBorderArrows = (value) => updateViewSettings({showBorderArrows: value});

    const setShowBorderOutline = (value) => updateViewSettings({showBorderOutline: value});

    const setShowCardOutline = (value) => updateViewSettings({showCardOutline: value});

    const setOutlineColor = (value) => updatePrintSettings({cardOutlineColor: value});

    const setShowSymbolOutline = (value) => updateViewSettings({showSymbolOutline: value});

    const handleShowBorderArrowsChange = (event) => {
        setShowBorderArrows(event.target.checked);
    };

    const handleShowBorderOutlineChange = (event) => {
        setShowBorderOutline(event.target.checked);
    };

    const handleShowCardOutlineChange = (event) => {
        setShowCardOutline(event.target.checked);
    };

    const handleShowSymbolOutlineChange = (event) => {
        setShowSymbolOutline(event.target.checked);
    };


    return (
        <Box pt={2} className="view-settings">
            <Grid container>
                <Grid item xs={12}>
                    <Box p={1}>
                        <Typography variant="subtitle2" component="h2">
                            Darstellung (irrelevant für Druck)
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box p={1}>
                        <FormControlLabel label="Randbegrenzungspfeile anzeigen"
                                          control={<Checkbox checked={showBorderArrows}
                                                             onChange={handleShowBorderArrowsChange}/>}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box p={1}>
                        <FormControlLabel label="Randbegrenzung anzeigen"
                                          control={<Checkbox checked={showBorderOutline}
                                                             onChange={handleShowBorderOutlineChange}/>}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box p={1}>
                        <FormControlLabel label={"Kartenaußenlinie anzeigen" + (cardOutline ? " (überschrieben durch Druckeinstellung)" : "")}
                                          control={<Checkbox checked={cardOutline || showCardOutline}
                                                             disabled={cardOutline}
                                                             onChange={handleShowCardOutlineChange}/>}
                        />

                        <SimpleColorPickerComponent color={cardOutlineColor} onChange={setOutlineColor}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box p={1}>
                        <FormControlLabel label="Symbolaußenlinie anzeigen"
                                          control={<Checkbox checked={showSymbolOutline}
                                                             onChange={handleShowSymbolOutlineChange}/>}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
