import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/Info";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export function InfoComponent() {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button color="inherit" onClick={handleOpen}>
                <InfoIcon/>
                Info
            </Button>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Info
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <em>Taktische Zeichen Vorlagen Generator</em> von <em>Tristan Lins</em>.<br/>
                        Lizensiert unter der Apache License 2.0.
                    </Typography>
                    <Typography gutterBottom>
                        <em>Taktische Zeichen</em> von <em>Jonas Köritz</em>.<br/>
                        Lizensiert unter der Creative Commons Attribution 4.0 International.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button href="https://github.com/jonas-koeritz/Taktische-Zeichen"
                            target="_blank" rel="noopener noreferrer">
                        Taktische Zeichen
                    </Button>
                    <Button href="https://gitlab.com/tristanlins/taktische-zeichen-vorlagen-generator/issues"
                            target="_blank" rel="noopener noreferrer">
                        Issues
                    </Button>
                    <Button href="https://gitlab.com/tristanlins/taktische-zeichen-vorlagen-generator"
                            target="_blank" rel="noopener noreferrer">
                        Repository
                    </Button>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
