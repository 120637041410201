import React, {useEffect, useState} from 'react';
import './App.scss';
import AppBar from "@material-ui/core/AppBar";
import {Toolbar} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Symbols from "./symbols";
import {SymbolSelectorComponent} from "./SymbolSelectorComponent";
import Button from "@material-ui/core/Button";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PrintIcon from '@material-ui/icons/Print';
import PageviewIcon from '@material-ui/icons/Pageview';
import AppsIcon from '@material-ui/icons/Apps';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {PrintAreaComponent} from "./PrintAreaComponent";
import {InfoComponent} from "./InfoComponent";
import {PrintSettingsComponent} from "./PrintSettingsComponent";
import Paper from "@material-ui/core/Paper";
import {ViewSettingsComponent} from "./ViewSettingsComponent";
import {FeedbackComponent} from "./FeedbackComponent";
import {TabPanel} from "./TabPanel";

const APP_VERSION = process.env.REACT_APP_VERSION || "<unknown>";
const RELEASES_URL = "https://gitlab.com/tristanlins/taktische-zeichen-vorlagen-generator/-/releases";

export const CardLayout = Object.freeze({
    SYMBOL_ONLY: 1,
    SYMBOL_WITH_SUBLINE: 2,
    SYMBOL_WITH_DETAILS: 3
});

/**
 * @typedef PrintSettings
 * @type {object}
 * @property {number} pageWidth
 * @property {number} pageHeight
 * @property {number} pageBorder
 * @property {number} cardLayout
 * @property {number} cardWidth
 * @property {number} cardHeight
 * @property {number} cardSpacing
 * @property {boolean} cardOutline
 * @property {string} cardOutlineColor
 * @property {boolean} cropMarks
 * @property {string} cropMarksColor
 * @property {number} symbolWidth
 * @property {number} symbolHeight
 * @property {string} textLineColor
 */

/**
 * @typedef ViewSettings
 * @type {object}
 * @property {boolean} showBorderArrows
 * @property {boolean} showBorderOutline
 * @property {boolean} showCardOutline
 * @property {boolean} showSymbolOutline
 */

/**
 * @typedef TacticalSymbol
 * @type {object}
 * @property {string} path
 * @property {string} name
 * @property {?number} amount
 */

/**
 * @typedef {Array<TacticalSymbol>} Group
 */

/**
 * @typedef {Object<string, Group>} Groups
 */

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
    },
    version: {
        color: "#ccc",
        fontSize: ".8rem"
    }
}));

/**
 * @return {ViewSettings}
 */
export function getDefaultViewSettings() {
    return {
        showBorderArrows: true,
        showBorderOutline: true,
        showCardOutline: true,
        showSymbolOutline: true,
    };
}

/**
 * @return {ViewSettings}
 */
function getInitialViewSettings() {
    try {
        const localSettings = JSON.parse(localStorage.getItem("viewSettings") || "{}");
        return Object.assign(getDefaultViewSettings(), localSettings);
    } catch (e) {
        console.error("Failed to read local viewSettings", e);
        return getDefaultViewSettings();
    }
}

export function getDefaultPageSize() {
    return {
        pageWidth: 210,
        pageHeight: 297,
        pageBorder: 20
    };
}

export function getDefaultCardSize() {
    return {
        cardLayout: CardLayout.SYMBOL_ONLY,
        cardWidth: 45,
        cardHeight: 48,
        cardSpacing: 0,
        cardOutline: true,
        cardOutlineColor: "#add8e6",
        cropMarks: true,
        cropMarksColor: "#add8e6",
        textLineColor: "#bfbfbf",
    };
}

export function getDefaultSymbolSize() {
    return {
        symbolWidth: 45,
        symbolHeight: 48
    };
}

/**
 * @return {PrintSettings}
 */
export function getDefaultPrintSettings() {
    return Object.assign(
        {},
        getDefaultPageSize(),
        getDefaultCardSize(),
        getDefaultSymbolSize()
    );
}

/**
 * @return {PrintSettings}
 */
function getInitialPrintSettings() {
    try {
        const localSettings = JSON.parse(localStorage.getItem("printSettings") || "{}");
        return Object.assign(getDefaultPrintSettings(), localSettings);
    } catch (e) {
        console.error("Failed to read local printSettings", e);
        return getDefaultPrintSettings();
    }
}

/**
 * @return {TacticalSymbol[]}
 */
function getInitialSelectedSymbols() {
    return [];
}

/**
 * @return {number}
 */
function getInitialTabIndex() {
    try {
        return JSON.parse(localStorage.getItem("tabIndex") || "0") || 0;
    } catch (e) {
        console.error("Failed to read local tabIndex", e);
        return 0;
    }
}

/**
 * The main app component.
 *
 * @return {*}
 * @constructor
 */
function App() {
    const classes = useStyles();

    const [viewSettings, setViewSettings] = useState(getInitialViewSettings());
    const [printSettings, setPrintSettings] = useState(getInitialPrintSettings());
    const [selectedSymbols, setSelectedSymbols] = useState(getInitialSelectedSymbols());
    const [tabIndex, setTabIndex] = useState(getInitialTabIndex());

    /**
     * Store view settings in the local storage.
     */
    useEffect(() => {
        try {
            localStorage.setItem("viewSettings", JSON.stringify(viewSettings));
        } catch (e) {
            console.warn("Failed to store view settings", e);
        }
    }, [viewSettings]);

    /**
     * Store print settings in the local storage.
     */
    useEffect(() => {
        try {
            localStorage.setItem("printSettings", JSON.stringify(printSettings));
        } catch (e) {
            console.warn("Failed to store print settings", e);
        }
    }, [printSettings]);

    /**
     * Load the available icons and restore selected symbols from the local storage.
     */
    useEffect(() => {
        const selectedPaths = JSON.parse(localStorage.getItem("selectedSymbols") || "[]");

        setSelectedSymbols(
            Object.values(Symbols).flatMap((symbols) => {
                return symbols.map(symbol => {
                    const selected = selectedPaths.find(item => symbol.path === item.path);
                    if (selected) {
                        symbol.amount = selected.amount || 1;
                        return symbol;
                    } else {
                        return null;
                    }
                }).filter(symbol => !!symbol);
            })
        );
    }, []);

    /**
     * Store selected symbols in the local storage.
     */
    useEffect(() => {
        localStorage.setItem("selectedSymbols", JSON.stringify(
            selectedSymbols.map(symbol => ({path: symbol.path, amount: symbol.amount || 1}))
        ));
    }, [selectedSymbols]);

    /**
     * Store selected symbols in the local storage.
     */
    useEffect(() => {
        localStorage.setItem("tabIndex", JSON.stringify(tabIndex));
    }, [tabIndex]);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const updateViewSettings = (payload) => {
        setViewSettings(currentValue => {
            return Object.assign({}, currentValue, payload);
        });
    };

    const updatePrintSettings = (payload) => {
        setPrintSettings(currentValue => {
            return Object.assign({}, currentValue, payload);
        });
    };

    return (
        <div className="app">
            <AppBar position="static" className="app-bar">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Taktische Zeichen Vorlagen Generator &nbsp; <a
                        className={classes.version} href={RELEASES_URL} target="_blank" rel="noopener noreferrer">{APP_VERSION}</a>
                    </Typography>
                    <FeedbackComponent/>
                    <Button color="inherit" onClick={() => window.print()}>
                        <PrintIcon/>
                        Drucken
                    </Button>
                    <InfoComponent/>
                </Toolbar>
            </AppBar>

            <div className="content">
                <PrintAreaComponent viewSettings={viewSettings}
                                    printSettings={printSettings}
                                    selectedSymbols={selectedSymbols}/>

                <Paper elevation={4} square className="aside-bar">
                    <AppBar position="static" color="default" elevation={0}>
                        <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
                            <Tab label="Zeichenauswahl" icon={<AppsIcon/>}/>
                            <Tab label="Darstellung" icon={<PageviewIcon/>}/>
                            <Tab label="Druckeinstellungen" icon={<PrintIcon/>}/>
                        </Tabs>
                    </AppBar>

                    <aside>
                        <TabPanel value={tabIndex} index={0}>
                            <SymbolSelectorComponent groups={Symbols}
                                                     selectedSymbols={selectedSymbols}
                                                     setSelectedSymbols={setSelectedSymbols}/>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <ViewSettingsComponent viewSettings={viewSettings}
                                                   updateViewSettings={updateViewSettings}
                                                   printSettings={printSettings}
                                                   updatePrintSettings={updatePrintSettings}/>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={2}>
                            <PrintSettingsComponent printSettings={printSettings}
                                                    updatePrintSettings={updatePrintSettings}/>
                        </TabPanel>
                    </aside>
                </Paper>
            </div>
        </div>
    );
}

export default App;
