import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import FeedbackIcon from '@material-ui/icons/Feedback';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {ListItemText} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import BugReportIcon from '@material-ui/icons/BugReport';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {TabPanel} from "./TabPanel";
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";

/**
 * @typedef Label
 * @type {object}
 * @property {string} id
 * @property {string} title
 * @property {string} color
 * @property {string} textColor
 *
 * @typedef LabelNodes
 * @type {object}
 * @property {Label[]} nodes
 *
 * @typedef Issue
 * @type {object}
 * @property {string} iid
 * @property {string} title
 * @property {string} webUrl
 * @property {LabelNodes} labels
 *
 * @typedef IssueNodes
 * @type {object}
 * @property {Issue[]} nodes
 *
 * @typedef Project
 * @type {object}
 * @property {IssueNodes} issues
 *
 * @typedef ProjectResponse
 * @type {object}
 * @property {Project} data
 */

const BUGS_TAB_INDEX = 0;
const FEATURES_TAB_INDEX = 1;
const SYMBOL_FEEDBACK_TAB_INDEX = 2;

const NEW_BUG_ISSUE_URL = "https://gitlab.com/tristanlins/taktische-zeichen-vorlagen-generator/issues/new?issuable_template=Bug";
const NEW_FEATURE_ISSUE_URL = "https://gitlab.com/tristanlins/taktische-zeichen-vorlagen-generator/issues/new?issuable_template=Feature";
const SERVICE_DESK_EMAIL = "incoming+tristanlins-taktische-zeichen-vorlagen-generator-16520854-issue-@incoming.gitlab.com";
const TAKTISCHE_ZEICHEN_REPOSITORY_URL = "https://github.com/jonas-koeritz/Taktische-Zeichen";
const TAKTISCHE_ZEICHEN_NEW_ISSUE_URL = "https://github.com/jonas-koeritz/Taktische-Zeichen/issues";

/**
 * The styles.
 *
 * @param theme
 * @return {{closeButton: {top: *, color: *, position: string, right: *}, root: {padding: *, margin: number}}}
 */
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

// language=graphql
const GRAPHQL_QUERY = `
    query Q($labelNames: [String!]) {
        project(fullPath: "tristanlins/taktische-zeichen-vorlagen-generator") {
            issues(state: opened, labelName: $labelNames, sort: updated_desc, first: 5) {
                nodes {
                    iid
                    title
                    webUrl
                    labels {
                        nodes {
                            id
                            title
                            color
                            textColor
                        }
                    }
                }
            }
        }
    }
`;

/**
 * Fetch issues.
 *
 * @param {string} label
 * @return {Promise<Issue[] | null>}
 */
function fetchIssues(label) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    return fetch("https://gitlab.com/api/graphql", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            query: GRAPHQL_QUERY,
            variables: {
                labelNames: [label]
            }
        })
    }).then(
        response => response.json()
    ).then(
        /** @param {ProjectResponse} response */
        response =>
            (response.data && response.data.project && response.data.project.issues)
                ? response.data.project.issues.nodes
                : null
    ).catch(
        reason => console.warn(`Failed to fetch "${label}" issues`, reason)
    );
}

function IssuesList(props) {
    /** @type Issue[] */
    const issues = props.issues;

    return (
        <List>
            {issues.map(issue => (
                <ListItem key={issue.iid} button component="a" href={issue.webUrl} target="_blank"
                          rel="noopener noreferrer">
                    <ListItemIcon>
                        {issue.labels.nodes.find(label => "Bug" === label.title) ? (
                            <BugReportIcon/>
                        ) : issue.labels.nodes.find(label => "Feature" === label.title) ? (
                            <PlusOneIcon/>
                        ) : (
                            <AssignmentIcon/>
                        )}
                    </ListItemIcon>
                    <ListItemText>
                        {issue.title}

                        {issue.labels.nodes.map(label => (
                            <Chip key={label.id} label={label.title}
                                  style={({backgroundColor: label.color, color: label.textColor, marginLeft: ".5em"})}/>
                        ))}
                    </ListItemText>
                </ListItem>
            ))}
            <ListItem button component="a"
                      href="https://gitlab.com/tristanlins/taktische-zeichen-vorlagen-generator/issues"
                      target="_blank" rel="noopener noreferrer">
                <ListItemIcon>
                    <OpenInNewIcon/>
                </ListItemIcon>
                <ListItemText>
                    weitere Vorgänge anzeigen
                </ListItemText>
            </ListItem>
        </List>
    );
}

function BugsTabPanel(props) {
    const {open, tabIndex} = props;

    const issuesState = useState(undefined);

    /**
     * @type {Issue[] | null}
     */
    const issues = issuesState[0];
    const setIssues = issuesState[1];


    useEffect(() => {
        if (open && BUGS_TAB_INDEX === tabIndex && undefined === issues) {
            fetchIssues("Bug").then(
                /** @param {Issue[]} issues */
                issues => setIssues(issues)
            );
        }
    }, [open, tabIndex, issues, setIssues]);

    return (
        <TabPanel value={tabIndex} index={BUGS_TAB_INDEX}>
            <Box p={4}>
                <Typography gutterBottom>
                    Du hast einen Fehler gefunden?
                    Dann prüfe bitte zuerst, ob das Problem bereits gemeldet wurde.
                </Typography>
                {undefined === issues ? (
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircularProgress/>
                            </ListItemIcon>
                            <ListItemText>Lade Vorgänge &hellip;</ListItemText>
                        </ListItem>
                    </List>
                ) : (issues && issues.length) ? (
                    <IssuesList issues={issues}/>
                ) : (
                    <Button href="https://gitlab.com/tristanlins/taktische-zeichen-vorlagen-generator/issues"
                            target="_blank" rel="noopener noreferrer">
                        Vorgänge anzeigen
                    </Button>
                )}
                <Typography gutterBottom>
                    Falls dein Problem bisher noch nicht gemeldet wurde, kannst du <a
                    href={NEW_BUG_ISSUE_URL} target="_blank" rel="noopener noreferrer">hier einen neuen Vorgang
                    erstellen</a>.
                    Falls du nicht mit Gitlab vertraut bist, kannst du auch <a
                    href={"mailto:" + SERVICE_DESK_EMAIL}>einfach eine E-Mail schreiben</a>.
                </Typography>
            </Box>
        </TabPanel>
    );
}

function FeaturesTabPanel(props) {
    const {tabIndex, open} = props;
    const issuesState = useState(undefined);

    /**
     * @type {Issue[] | null}
     */
    const issues = issuesState[0];
    const setIssues = issuesState[1];

    useEffect(() => {
        if (open && FEATURES_TAB_INDEX === tabIndex && undefined === issues) {
            fetchIssues("Feature").then(
                /** @param {Issue[]} issues */
                issues => setIssues(issues)
            );
        }
    }, [open, tabIndex, issues, setIssues]);

    return (
        <TabPanel value={tabIndex} index={FEATURES_TAB_INDEX}>
            <Box p={4}>
                <Typography gutterBottom>
                    Du hast eine Idee, wie man den Generator verbessern könnte?
                    Dann prüfe bitte zuerst, ob die gewünschte Verbesserung bereits gemeldet wurde.
                </Typography>
                {undefined === issues ? (
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CircularProgress/>
                            </ListItemIcon>
                            <ListItemText>Lade Vorgänge &hellip;</ListItemText>
                        </ListItem>
                    </List>
                ) : (issues && issues.length) ? (
                    <IssuesList issues={issues}/>
                ) : (
                    <Button href="https://gitlab.com/tristanlins/taktische-zeichen-vorlagen-generator/issues"
                            target="_blank" rel="noopener noreferrer">
                        Vorgänge anzeigen
                    </Button>
                )}
                <Typography gutterBottom>
                    Falls dein Verbesserungswunsch bisher noch nicht gemeldet wurde, kannst du <a
                    href={NEW_FEATURE_ISSUE_URL} target="_blank" rel="noopener noreferrer">hier einen neuen Vorgang
                    erstellen</a>.
                    Falls du nicht mit Gitlab vertraut bist, kannst du auch <a
                    href={"mailto:" + SERVICE_DESK_EMAIL} target="_blank" rel="noopener noreferrer">einfach eine E-Mail
                    schreiben</a>.
                </Typography>
            </Box>
        </TabPanel>
    );
}

function SymbolFeedbackTabPanel(props) {
    const {tabIndex, setTabIndex} = props;

    return (
        <TabPanel value={tabIndex} index={SYMBOL_FEEDBACK_TAB_INDEX}>
            <Box p={4}>
                <Typography gutterBottom>
                    Der Generator verwendet die <a
                    href={TAKTISCHE_ZEICHEN_REPOSITORY_URL} target="_blank" rel="noopener noreferrer">Taktischen Zeichen
                    von Jonas Köritz</a>. Falls es Probleme mit den Taktischen Zeichen gibt (z.B. dass diese falsch
                    sind) oder du Verbesserungs- oder Ergänzungsvorschläge hast (z.B. weil Taktische Zeichen fehlen)
                    dann <a href={TAKTISCHE_ZEICHEN_NEW_ISSUE_URL} target="_blank" rel="noopener noreferrer">melde dies
                    bitte direkt bei Jonas auf Github</a>.
                </Typography>
                <Typography gutterBottom>
                    PS: Ich versuche die Taktischen Zeichen, die der Generator verwendet immer auf dem aktuellen
                    Entwicklungsstand zu halten. Natürlich kann es passieren, dass diese nicht aktuell sind.
                    Sollte ein Update der Taktischen Zeichen noch nicht erfolgt sein, musst du dies natürlich nicht bei
                    Jonas, sondern <Button component="a" size={"small"} onClick={() => setTabIndex(FEATURES_TAB_INDEX)}>direkt bei mir
                    melden</Button>.
                </Typography>
            </Box>
        </TabPanel>
    );
}

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0),
    },
}))(MuiDialogContent);

function FeedbackDialog(props) {
    const {open, handleClose} = props;

    const [tabIndex, setTabIndex] = useState(BUGS_TAB_INDEX);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={"md"}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Feedback
            </DialogTitle>
            <DialogContent dividers>
                <AppBar position="static" color="default" elevation={0}>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="Problem" icon={<BugReportIcon/>} value={BUGS_TAB_INDEX}/>
                        <Tab label="Verbesserung" icon={<PlusOneIcon/>} value={FEATURES_TAB_INDEX}/>
                        <Tab label="Taktischen Zeichen" icon={<EmojiSymbolsIcon/>} value={SYMBOL_FEEDBACK_TAB_INDEX}/>
                    </Tabs>
                </AppBar>

                <BugsTabPanel tabIndex={tabIndex} open={open}/>
                <FeaturesTabPanel tabIndex={tabIndex} open={open}/>
                <SymbolFeedbackTabPanel tabIndex={tabIndex} setTabIndex={setTabIndex}/>

            </DialogContent>
        </Dialog>
    );
}

export function FeedbackComponent() {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button color="inherit" onClick={handleOpen}>
                <FeedbackIcon/>
                Feedback
            </Button>
            <FeedbackDialog open={open} handleClose={handleClose}/>
        </div>
    )
}
