import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

export function PageLayoutComponent(props) {
    const {
        printSettings,
        updatePrintSettings
    } = props;

    let pageWidth = printSettings.pageWidth;
    let pageHeight = printSettings.pageHeight;
    let pageBorder = printSettings.pageBorder;

    const setWidth = (value) => updatePrintSettings({pageWidth: value});

    const setHeight = (value) => updatePrintSettings({pageHeight: value});

    const setBorder = (value) => updatePrintSettings({pageBorder: value});

    const handleWidthChange = (event) => {
        setWidth(parseInt(event.target.value));
    };

    const handleHeightChange = (event) => {
        setHeight(parseInt(event.target.value));
    };

    const handleBorderChange = (event) => {
        setBorder(parseInt(event.target.value));
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const loadA4 = () => {
        updatePrintSettings({
            pageWidth: 210,
            pageHeight: 297,
        });
        setAnchorEl(null);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box p={1}>
                    <Typography variant="subtitle2" component="h2">
                        Papierlayout
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box p={1}>
                    <TextField label="Papierbreite (mm)"
                               inputProps={({type: "number", min: 0, step: 1})}
                               value={pageWidth}
                               onChange={handleWidthChange}/>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box p={1}>
                    <TextField label="Papierhöhe (mm)"
                               inputProps={({type: "number", min: 0, step: 1})}
                               value={pageHeight}
                               onChange={handleHeightChange}/>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box p={1}>
                    <TextField label="Druckrand (mm)"
                               inputProps={({type: "number", min: 0, step: 1})}
                               value={pageBorder}
                               onChange={handleBorderChange}/>
                </Box>
            </Grid>
            <Grid item xs={1}>
                <Box pt={2}>
                    <Tooltip title="Voreinstellung laden" children={(
                        <IconButton variant="contained" onClick={handleClick}>
                            <RotateLeftIcon/>
                        </IconButton>
                    )}/>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={loadA4}>A4 (210mm &times; 297mm)</MenuItem>
                    </Menu>
                </Box>
            </Grid>
        </Grid>
    )
}