import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import {CardLayout} from "./App";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {SimpleColorPickerComponent} from "./SimpleColorPickerComponent";

/**
 * @typedef PrintSettings
 * @type {object}
 * @property {number} pageWidth
 * @property {number} pageHeight
 * @property {number} pageBorder
 * @property {number} cardLayout
 * @property {number} cardWidth
 * @property {number} cardHeight
 * @property {number} cardSpacing
 * @property {boolean} cardOutline
 * @property {string} cardOutlineColor
 * @property {boolean} cropMarks
 * @property {string} cropMarksColor
 * @property {number} symbolWidth
 * @property {number} symbolHeight
 * @property {string} textLineColor
 */

export function CardLayoutComponent(props) {
    const {
        printSettings,
        updatePrintSettings
    } = props;

    const cardLayout = printSettings.cardLayout;
    const cardWidth = printSettings.cardWidth;
    const cardHeight = printSettings.cardHeight;
    const cardSpacing = printSettings.cardSpacing;
    const cardOutline = printSettings.cardOutline;
    const cardOutlineColor = printSettings.cardOutlineColor;
    const cropMarks = printSettings.cropMarks;
    const cropMarksColor = printSettings.cropMarksColor;
    const textLineColor = printSettings.textLineColor;

    const setCardLayout = (value) => updatePrintSettings({cardLayout: value});

    const setCardWidth = (value) => updatePrintSettings({cardWidth: value});

    const setCardHeight = (value) => updatePrintSettings({cardHeight: value});

    const setSpacing = (value) => updatePrintSettings({cardSpacing: value});

    const setOutline = (value) => updatePrintSettings({cardOutline: value});

    const setOutlineColor = (value) => updatePrintSettings({cardOutlineColor: value});

    const setCropMarks = (value) => updatePrintSettings({cropMarks: value});

    const setCropMarksColor = (value) => updatePrintSettings({cropMarksColor: value});

    const setTextLineColor = (value) => updatePrintSettings({textLineColor: value});

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const loadCProfilePreset = () => {
        updatePrintSettings({cardWidth: 45, cardHeight: 48});
        setAnchorEl(null);
    };

    const handleCardLayoutChange = (newCardLayout) => {
        if (CardLayout.SYMBOL_WITH_DETAILS === cardLayout && CardLayout.SYMBOL_WITH_DETAILS !== newCardLayout) {
            setCardWidth(cardWidth / 2);
        } else if (CardLayout.SYMBOL_WITH_DETAILS !== cardLayout && CardLayout.SYMBOL_WITH_DETAILS === newCardLayout) {
            setCardWidth(2 * cardWidth);
        }

        setCardLayout(newCardLayout);
    };

    const handleCardWidthChange = (event) => {
        setCardWidth(parseInt(event.target.value));
    };

    const handleCardHeightChange = (event) => {
        setCardHeight(parseInt(event.target.value));
    };

    const handleSpacingChange = (event) => {
        setSpacing(parseInt(event.target.value));
    };

    const handleCardOutlineChange = (event) => {
        setOutline(event.target.checked);
    };

    const handleCropMarksChange = (event) => {
        setCropMarks(event.target.checked);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box p={1}>
                    <Typography variant="subtitle2" component="h2">
                        Kartenlayout
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box p={1}>
                    <BottomNavigation value={cardLayout}
                                      showLabels
                                      onChange={(event, newValue) => handleCardLayoutChange(newValue)}
                                      style={({height: "auto"})}>
                        <BottomNavigationAction label="Nur Symbol"
                                                value={CardLayout.SYMBOL_ONLY}
                                                icon={<Avatar variant="rounded">
                                                    <img alt="Nur Symbol" src="symbol-only.svg" width="30"/>
                                                </Avatar>}/>

                        <BottomNavigationAction label="Symbol mit Textlinie"
                                                value={CardLayout.SYMBOL_WITH_SUBLINE}
                                                icon={<Avatar variant="rounded">
                                                    <img alt="Symbol mit Textlinie" src="symbol-with-subline.svg"
                                                         width="30"/>
                                                </Avatar>}/>

                        <BottomNavigationAction label="Symbol mit Details"
                                                value={CardLayout.SYMBOL_WITH_DETAILS}
                                                icon={<Avatar variant="rounded">
                                                    <img alt="Symbol mit Details (doppelte Breite)"
                                                         src="symbol-with-details.svg"
                                                         width="30"/>
                                                </Avatar>}/>
                    </BottomNavigation>
                </Box>
            </Grid>

            <Grid item xs={3}>
                <Box p={1}>
                    <TextField label="Breite der Karte (mm)"
                               inputProps={({type: "number", min: 20, step: 1})}
                               value={cardWidth}
                               onChange={handleCardWidthChange}/>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box p={1}>
                    <TextField label="Höhe der Karte (mm)"
                               inputProps={({type: "number", min: 20, step: 1})}
                               value={cardHeight}
                               onChange={handleCardHeightChange}/>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box p={1}>
                    <TextField label="Abstand (mm)"
                               inputProps={({type: "number", min: 0, step: 1})}
                               value={cardSpacing}
                               onChange={handleSpacingChange}/>
                </Box>
            </Grid>
            {(CardLayout.SYMBOL_WITH_SUBLINE === cardLayout || CardLayout.SYMBOL_WITH_DETAILS === cardLayout) ? (
                <Grid item xs={12}>
                    <Box p={1}>
                        <FormControlLabel label="Linienfarbe"
                                          control={<SimpleColorPickerComponent color={textLineColor}
                                                                               onChange={setTextLineColor}
                                                                               colors={["#bfbfbf", "#808080", "#404040", "#000"]}
                                          style={({ padding: "9px" })}/>}
                        />
                    </Box>
                </Grid>
            ) : null}
            <Grid item xs={12}>
                <Box p={1}>
                    <FormControlLabel label="Kartenaußenlinie drucken"
                                      control={<Checkbox checked={cardOutline}
                                                         onChange={handleCardOutlineChange}/>}
                    />

                    <SimpleColorPickerComponent color={cardOutlineColor} onChange={setOutlineColor}/>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box p={1}>
                    <FormControlLabel label="Schnittmarken drucken"
                                      control={<Checkbox checked={cropMarks}
                                                         onChange={handleCropMarksChange}/>}
                    />

                    <SimpleColorPickerComponent color={cropMarksColor} onChange={setCropMarksColor}/>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box pt={2}>
                    <Tooltip title="Voreinstellung laden" children={(
                        <IconButton variant="contained" onClick={handleClick}>
                            <RotateLeftIcon/>
                        </IconButton>
                    )}/>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={loadCProfilePreset}>C-Profil (45mm &times; 48mm)</MenuItem>
                    </Menu>
                </Box>
            </Grid>
        </Grid>
    )
}
